import Honeybadger from "@honeybadger-io/js";

export const request = (method, path, body, setUnauthorized = () => {}) => {
  const requestOptions = {
    method: method,
    credentials: 'include',
    redirect: 'follow'
  };

  if (body) {
    requestOptions.body = body
  }

  if (!(body instanceof FormData)) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    headers.append("App-Version", process.env.REACT_APP_COMMIT_HASH);

    requestOptions.headers = headers
  }

  let successfulResponse;

  return fetch(`${process.env.REACT_APP_ROOT_DOMAIN}${path}`, requestOptions)
    .then(response => {
      if (response.ok) {
        successfulResponse = true;
        return response.json();
      }
      else if (response.status === 401) {
        setUnauthorized(true)
        return response.json()
      }
      else if (response.status.toString()[0] === "4") {
        return response.json()
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (successfulResponse) {
        return Promise.resolve(data)
      }
      return Promise.reject(data)
    })
}

// Send unforseen errors to Honeybadger
export const reportErrorToHoneybadger = (error) => {
  if (error instanceof Error && error.message !== "Something went wrong ...") {
    Honeybadger.notify(error)
  }
}
