import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { request, reportErrorToHoneybadger } from '../../helpers/Requests'
import SetTitle from '../../components/shared/SetTitle';
import HelpText from '../../components/help/HelpText';
import NoticeBox from '../../components/shared/NoticeBox';
import Audits from '../../components/shared/Audits';

import { adminUser, managerUser, renderErrorWarning, renderErrors } from '../../utilities/Forms.js'
import { arrayParamBuilder } from '../../utilities/Generic.js'

class CreateBulletin extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleAllCheckbox = this.handleAllCheckbox.bind(this);
    this.handleMultiCheckbox = this.handleMultiCheckbox.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.calculateRecipientCount = this.calculateRecipientCount.bind(this);
  }

  state = {
    title: "",
    body: "",

    location_ids: [],
    role_ids: [],
    tag_ids: [0],

    locations: [],
    roles: [],
    tags: [],

    calculating: false,
    recipientCount: 0,

    filter_by_tags: "false",

    locationsLoaded: false,
    rolesLoaded: false,
    tagsLoaded: false,
    updated: "",
    unauthorized: "",
    errors: "",
    error: ""
  };

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});

    if (name === "filter_by_tags") {
      this.calculateRecipientCount("filter_by_tags", value)
    }
  }

  handleAllCheckbox(event) {
    let key = event.target.dataset.key;
    let value = event.target.checked;

    var state = this.state[key]

    if (value === true) {
      const data = key.replace("_ids", "s");
      state = this.state[data].map(datum => datum.id)
    }
    else if (value === false) {
      state = []
    }

    this.setState({[key]: state});

    this.calculateRecipientCount(key, state)
  }

  handleMultiCheckbox(event) {
    let name = parseInt(event.target.name);
    let key = event.target.dataset.key;
    let value = event.target.checked;

    var state = this.state[key]

    if (value === true) {
      if (!state.includes(name)) {
        state.push(name)
      }
    }
    else if (value === false) {
      if (state.includes(name)) {
        let index = state.indexOf(name)
        state.splice(index, 1)
      }
    }

    this.setState({[key]: state});

    this.calculateRecipientCount(key, state)
  }

  calculateRecipientCount(key, value) {
    const location_ids = key === "location_ids" ? value : this.state.location_ids
    const role_ids = key === "role_ids" ? value : this.state.role_ids
    let tag_ids = key === "tag_ids" ? value : this.state.tag_ids
    const filter_by_tags = key === "filter_by_tags" ? value : this.state.filter_by_tags

    if (filter_by_tags === "false") {
      tag_ids = [0]
    }
    else {
      tag_ids = tag_ids.filter(tag_id => tag_id !== 0);
    }

    if (location_ids.length === 0 || role_ids.length === 0 || tag_ids.length === 0) {
      return
    }

    const params = `${arrayParamBuilder("bulletin", "location_ids", location_ids)}&${arrayParamBuilder("bulletin", "role_ids", role_ids)}&${arrayParamBuilder("bulletin", "tag_ids", tag_ids)}`

    this.setState({calculating: true})
    request('GET', `/v1/a/bulletins/recipient_count?${params}`, null, () => {this.setState({ unauthorized: true })})
    .then(data => {
      this.setState({ recipientCount: data.count, calculating: false, errors: "" })
    })
    .catch(error => {
      reportErrorToHoneybadger(error)

      if (error instanceof Error) {
        this.setState({ updating: false, error: error })
      }
      else {
        this.setState({ updating: false, errors: error })
      }
    })
  }

  handleSubmit(event) {
    let id = this.props.id;

    let tag_ids = this.state.tag_ids

    if (this.state.filter_by_tags === "false") {
      tag_ids = [0]
    }
    else {
      tag_ids = tag_ids.filter(tag_id => tag_id !== 0);
    }

    const json = JSON.stringify({
      "bulletin": {
        "title": this.state.title,
        "body": this.state.body,
        "location_ids": this.state.location_ids,
        "role_ids": this.state.role_ids,
        "tag_ids": tag_ids,
      }
    })

    request('PUT', `/v1/a/bulletins/${id}`, json, () => {this.setState({ unauthorized: true })})
      .then(data => {
        this.setState({ errors: "" })
        sessionStorage.setItem("updateSuccess", "Bulletin updated successfully!");
        this.props.closeEditPanel()
      })
      .catch(error => {
        reportErrorToHoneybadger(error)

        if (error instanceof Error) {
          this.setState({ error: error })
        }
        else {
          this.setState({ errors: error })
        }
      })

    event.preventDefault();
  }

  renderUpdated(updated) {
    if (updated) {
      return (
        <div>
          Created!
        </div>
      );
    }
  }

  renderSaveButton() {
    if (adminUser() || managerUser()) {
      return <input type="submit" value="Save" disabled={this.state.updating} />
    }
    else {
      return <input type="submit" value="Sorry, you don't have permission to save changes" disabled="disabled" />
    }
  }

  renderRecipientCount() {
    if (this.state.location_ids.length === 0 || this.state.role_ids.length === 0 || this.state.tag_ids.length === 0) {
      return null
    }
    else if (this.state.calculating === true) {
      return (<div className="center">Calculaing...</div>)
    }
    else {
      return (
        <div className="center">
          This bulletin was sent to {this.state.recipientCount} recipients.
        </div>
      )
    }
  }

  render() {
    const { title, body, location_ids, role_ids, tag_ids, locations, roles, tags, filter_by_tags, loaded, locationsLoaded, rolesLoaded, tagsLoaded, id, updated, unauthorized, errors, error } = this.state;

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (updated && id) {
      return <Redirect to={`/bulletins/${id}`}/>
    }

    if (loaded === false || locationsLoaded === false || rolesLoaded === false || tagsLoaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded && locationsLoaded && rolesLoaded && tagsLoaded) {
      let disableLocations = false

      if (location_ids.length > locations.length && adminUser() === false) {
        disableLocations = true
      }

      return (
        <div>
          <SetTitle title={"Edit Bulletin | Bulletins"} />

          <form className="settings big-settings" onSubmit={this.handleSubmit}>
            <div className="row">
              <HelpText page={'bulletin'} section={'title'} />

              <label className="column">Title:</label>{ renderErrors(errors, 'title') }
              <input className="column" type="text" name="title" value={title} onChange={this.handleChange} />
            </div>

            <div className="row">
              <div className="textbox">
                <HelpText page={'bulletin'} section={'body'} />

                <label className="column">Body:</label>{ renderErrors(errors, 'body') }
                <textarea aria-required="true" name="body" value={body} onChange={this.handleChange} />
              </div>
            </div>

            {disableLocations === true ? (
              <NoticeBox type="info">
                <div>This Bulletin has gone out to a Location you don't have access to, therefore you cannot edit the list of Locations</div>
              </NoticeBox>
            ) : null}

            <div className="row small-bottom-margin">
              <HelpText page={'bulletin'} section={'location_ids'} />

              <div className="row">
                <label className="column center-column">Locations:</label>{ renderErrors(errors, 'location_ids') }
              </div>

              <input className="column" type="checkbox" id="all-locations" disabled={disableLocations} checked={location_ids.length === locations.length} data-key="location_ids" onChange={this.handleAllCheckbox} />
              <label className="column checkbox-label" htmlFor="all-locations">All Locations</label>

              {locations.map((location) => (
                <>
                  <input className="column" type="checkbox" name={location.id} id={`location-${location.id}`} disabled={disableLocations} checked={location_ids.includes(location.id)} data-key="location_ids" onChange={this.handleMultiCheckbox} />
                  <label className="column checkbox-label" htmlFor={`location-${location.id}`}>{location.name}</label>
                </>
              ))}
            </div>

            <div className="row small-bottom-margin">
              <HelpText page={'bulletin'} section={'role_ids'} />

              <div className="row">
                <label className="column center-column">Roles:</label>{ renderErrors(errors, 'role_ids') }
              </div>

              <input className="column" type="checkbox" id="all-roles" checked={role_ids.length === roles.length} data-key="role_ids" onChange={this.handleAllCheckbox} />
              <label className="column checkbox-label" htmlFor="all-roles">All Roles</label>

              {roles.map((role) => (
                <>
                  <input className="column" type="checkbox" name={role.id} id={`role-${role.id}`} checked={role_ids.includes(role.id)} data-key="role_ids" onChange={this.handleMultiCheckbox} />
                  <label className="column checkbox-label" htmlFor={`role-${role.id}`}>{role.name}</label>
                </>
              ))}
            </div>

            <div className="row small-bottom-margin">
              <HelpText page={'bulletin'} section={'filter_by_tags'} />

              <div className="row">
                <label className="column center-column">Filter by tags?</label>
              </div>

               <div className="radio-buttons">
                <div className="radio-button">
                  <input className="column no-bottom-margin" type="radio" name="filter_by_tags" id="false" value="false" checked={filter_by_tags === "false"} onChange={this.handleChange} />
                  <label htmlFor="false">No</label>
                </div>

                <div className="radio-button">
                  <input className="column no-bottom-margin" type="radio" name="filter_by_tags" id="true" value="true" checked={filter_by_tags === "true"} onChange={this.handleChange} />
                  <label htmlFor="true">Yes</label>
                </div>
              </div>
            </div>

            {filter_by_tags === "true" ? (
              <div className="row small-bottom-margin">
                <HelpText page={'bulletin'} section={'tag_ids'} />

                <div className="row">
                  <label className="column center-column">Tags:</label>{ renderErrors(errors, 'tag_ids') }
                </div>

                <input className="column" type="checkbox" id="all-tags" checked={tag_ids.length === tags.length} data-key="tag_ids" onChange={this.handleAllCheckbox} />
                <label className="column checkbox-label" htmlFor="all-tags">All Tags</label>

                {tags.map((tag) => (
                  <>
                    <input className="column" type="checkbox" name={tag.id} id={`tag-${tag.id}`} checked={tag_ids.includes(tag.id)} data-key="tag_ids" onChange={this.handleMultiCheckbox} />
                    <label className="column checkbox-label" htmlFor={`tag-${tag.id}`}>{tag.name}</label>
                  </>
                ))}
              </div>
            ) : null}


            { this.renderRecipientCount() }

            { this.renderSaveButton() }
            { this.renderUpdated(updated) }
            { renderErrorWarning(errors) }
          </form>

          <Audits klass="Bulletin" id={this.props.id} />
        </div>
      );
    }
  }

  componentDidMount() {
    let id = this.props.id;

    request('GET', `/v1/a/bulletins/${id}`, null, () => {this.setState({ unauthorized: true })})
      .then(data => {
        this.setState({
          title: data.title,
          body: data.body,
          location_ids: data.location_ids,
          role_ids: data.role_ids,
          tag_ids: data.tag_ids,
          filter_by_tags: data.tag_ids.length === 1 && data.tag_ids[0] === 0 ? "false" : "true",
          loaded: true
        })

        this.calculateRecipientCount("", "")
      })
      .catch(error => {
        reportErrorToHoneybadger(error)

        this.setState({ error, loaded: true })
      })

    request('GET', `/v1/a/locations/names_and_ids`, null, () => {this.setState({ unauthorized: true })})
      .then(data => {
        this.setState({ locations: data, locationsLoaded: true })
      })
      .catch(error => {
        reportErrorToHoneybadger(error)

        this.setState({ error, locationsLoaded: true })
      })

    request('GET', `/v1/a/roles/names_and_ids`, null, () => {this.setState({ unauthorized: true })})
      .then(data => {
        this.setState({ roles: data, rolesLoaded: true })
      })
      .catch(error => {
        reportErrorToHoneybadger(error)

        this.setState({ error, rolesLoaded: true })
      })


    request('GET', `/v1/a/tags/names_and_ids`, null, () => {this.setState({ unauthorized: true })})
      .then(data => {
        this.setState({ tags: data, tagsLoaded: true })
      })
      .catch(error => {
        reportErrorToHoneybadger(error)

        this.setState({ error, tagsLoaded: true })
      })
  }
}

export default CreateBulletin;
