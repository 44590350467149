import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { request, reportErrorToHoneybadger } from '../../helpers/Requests'
import SetTitle from '../../components/shared/SetTitle';
import HelpText from '../../components/help/HelpText';

import { adminUser, managerUser, renderErrorWarning, renderErrors } from '../../utilities/Forms.js'
import { debounce } from '../../utilities/Generic.js'

class CreateAction extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePartialChange = this.handlePartialChange.bind(this);
    this.debouncedHandlePersonSearch = debounce(this.handlePersonSearch.bind(this), 500);
  }

  state = {
    problem_type: "",
    date_of_problem: "",
    problem: "",
    person_id: "",
    location_id: "",

    partial: "",
    people: [],

    locationsLoaded: false,
    peopleLoaded: true,
    unauthorized: "",
    errors: "",
    error: ""
  };

  colourScore(score) {
    if (score === null) {
      return "grey-text"
    }
    if (score < 0) {
      return "red-text"
    }
    if (score > 0) {
      return "green-text"
    }
    else {
      return ""
    }
  }

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});

    if (name === "location_id") {
      this.handlePersonSearch(name, value)
    }
  }

  handlePartialChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({
      [name]: value,
      peopleLoaded: false
    });

    this.debouncedHandlePersonSearch(name, value)
  }


  handlePersonSearch(name, value) {
    if ((name === "partial" && value === "") || this.state.partial === "") {
      this.setState({
        people: [],
        peopleLoaded: true
      })

      return
    }

    let params

    if (name === "partial") {
      if (this.state.location_id !== "") {
        params = `&person[partial]=${value}&person[location]=${this.state.location_id}`
      }
      else {
        params = `&person[partial]=${value}`
      }
    }
    else {
      params = `&person[partial]=${this.state.partial}&person[location]=${value}`
    }

    request('GET', `/v1/a/people/scoped_names_and_ids?${params}`, null, () => {this.setState({ unauthorized: true })})
      .then(data => {
        this.setState({ people: data, peopleLoaded: true })
      })
      .catch(error => {
        reportErrorToHoneybadger(error)

        this.setState({ error, peopleLoaded: true })
      })
  }

  handleSubmit(event) {
    this.setState({
      updating: true
    })

    const json = JSON.stringify({
      "outstanding_action": {
        "action_status": "open",
        "problem_type": this.state.problem_type,
        "date_of_problem": this.state.date_of_problem,
        "problem": this.state.problem,
        "person_id": this.state.person_id,
        "location_id": this.state.location_id
      }
    })

    request('POST', `/v1/a/outstanding_actions`, json, () => {this.setState({ unauthorized: true })})
      .then(data => {
        this.setState({
          updating: false,
          errors: "",
        })

        sessionStorage.setItem("updateSuccess", `Action created successfully!`);
        this.props.closePanel()
      })
      .catch(error => {
        reportErrorToHoneybadger(error)

        if (error instanceof Error) {
          this.setState({ updating: false, error: error })
        }
        else {
          this.setState({ updating: false, errors: error })
        }
      })

    event.preventDefault();
  }

  renderSaveButton() {
    if (adminUser() || managerUser()) {
      return <input type="submit" value="Save" />
    }
    else {
      return <input type="submit" value="Sorry, you don't have permission to save changes" disabled="disabled" />
    }
  }

  renderSelectBox(name, value, loaded) {
    if (loaded === false) {
      return (
        <select name={name} onChange={this.handleChange} value={value}>
          <option value="">Loading...</option>
        </select>
      )
    }

    const people = this.state.people
    const visitors = people.filter((person) => person.type_of_person === "visitor")
    const residents = people.filter((person) => person.type_of_person === "resident")
    const staff = people.filter((person) => person.type_of_person === "staff")
    const contractors = people.filter((person) => person.type_of_person === "contractor")
    const industry_professionals = people.filter((person) => person.type_of_person === "industry professional")
    const other = people.filter((person) => person.type_of_person === "other")

    return (
      <select name={name} onChange={this.handleChange} value={value}>
        <option value="">Select</option>
        <optgroup label="Visitors">
          {visitors.map((person) => (
            <option value={person.id} key={person.id}>{person.full_name}</option>
          ))}
        </optgroup>
        <optgroup label="Residents">
          {residents.map((person) => (
            <option value={person.id} key={person.id}>{person.full_name}</option>
          ))}
        </optgroup>
        <optgroup label="Staff">
          {staff.map((person) => (
            <option value={person.id} key={person.id}>{person.full_name}</option>
          ))}
        </optgroup>
        <optgroup label="Contractor">
          {contractors.map((person) => (
            <option value={person.id} key={person.id}>{person.full_name}</option>
          ))}
        </optgroup>
        <optgroup label="Industry Professional">
          {industry_professionals.map((person) => (
            <option value={person.id} key={person.id}>{person.full_name}</option>
          ))}
        </optgroup>
        <optgroup label="Other">
          {other.map((person) => (
            <option value={person.id} key={person.id}>{person.full_name}</option>
          ))}
        </optgroup>
      </select>
    )
  }

  render() {
    const { problem_type, date_of_problem, problem, person_id, location_id, locations, locationsLoaded, peopleLoaded, unauthorized, error, errors } = this.state;

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (locationsLoaded === false) {
      return <p>Loading ...</p>;
    }

    if (locationsLoaded) {
      return (
        <div>
          <SetTitle title={`Create Action | Outstanding Actions`} />

          <h3>Create Action</h3>
          <form className="settings big-settings" onSubmit={this.handleSubmit}>

            <div className="row">
              <HelpText page={'device'} section={'location'} />

              <label className="column">Location:</label>{ renderErrors(errors, 'location') }
              <select name="location_id" onChange={this.handleChange} value={location_id}>
                <option value="">Select</option>
                {locations.map((location) => (
                  <option value={location.id} key={location.id}>{location.name}</option>
                ))}
              </select>
            </div>

            <div className="row">
              <HelpText page={'outstanding_actions'} section={'person'} />

              <label className="column">Person:</label>{ renderErrors(errors, 'person') }
              <input className="no-bottom-margin" name="partial" placeholder="Enter text to search" onChange={this.handlePartialChange} value={this.state.partial} />
              { this.renderSelectBox("person_id", person_id, peopleLoaded) }
            </div>

            <div className="row">
              <HelpText page={'outstanding_actions'} section={'problem_type'} />

              <label className="column">Problem Type:</label>{ renderErrors(errors, 'problem_type') }
              <select name="problem_type" onChange={this.handleChange} value={problem_type}>
                <option value="">Select</option>
                <option value="complaint">Complaint</option>
                <option value="maintenance">Maintenance</option>
              </select>
            </div>

            <div className="row">
              <HelpText page={'outstanding_actions'} section={'problem'} />

              <label className="column">Problem:</label>{ renderErrors(errors, 'problem') }
              <textarea aria-required="true" name="problem" value={problem} onChange={this.handleChange} />
            </div>

            <div className="row">
              <HelpText page={'outstanding_actions'} section={'date_of_problem'} />

              <label className="column">Date of Problem:</label>{ renderErrors(errors, 'date_of_problem') }
              <input className="column" type="date" name="date_of_problem" value={date_of_problem} onChange={this.handleChange} />
            </div>

            {this.renderSaveButton()}
            { renderErrorWarning(errors) }
          </form>
        </div>
      );
    }
  }

  componentDidMount() {
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ locations: data, locationsLoaded: true })
      })
      .catch(error => this.setState({ error, locationsLoaded: true }))
  }
}

export default CreateAction;
