import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

import { request, reportErrorToHoneybadger } from '../../helpers/Requests'
import SetTitle from '../../components/shared/SetTitle';
import NoticeBox from '../../components/shared/NoticeBox';
import HelpText from '../../components/help/HelpText';

import { adminUser, supportUser, renderErrorWarning } from '../../utilities/Forms.js'
import { debounce } from '../../utilities/Generic.js'

class CreateLocation extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleMultiCheckbox = this.handleMultiCheckbox.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handlePartialChange = this.handlePartialChange.bind(this);
    this.debouncedHandlePersonSearch = debounce(this.handlePersonSearch.bind(this), 500);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.clearImageUpload = this.clearImageUpload.bind(this);
  }

  state = {
    name: "",
    timezone: "",
    timezones: [],
    regulator_reference: "",
    postcode: "",
    address: "",
    phone: "",
    hidden: false,
    background_image: null,
    upload_file: null,
    welcome_message: "Welcome to",
    device_help_message: "Please select the type of person you are, or scan the QR code to continue. For help to know how to use a QR scanner please visit www.dreception.com/help.",
    text_on_select: "Please select a Profile",
    arrival_thank_you_message: "Thank you. Enjoy your stay.",
    departure_thank_you_message: "Thank you. Please visit again soon.",
    fire_logs_email: "",
    fire_log_access_pin: "",
    email_fire_log: false,
    user_ids: [],
    fire_log_user_ids: [],

    users: [],
    partial: "",
    searchedUsers: null,
    newLocationText: "",

    usersLoaded: false,
    timezonesLoaded: false,
    organisationTimezoneLoaded: false,
    newLocationTextLoaded: false,
    updated: "",
    unauthorized: "",
    errors: "",
    error: ""
  };

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});

    if (name === "name" && this.state.welcome_message.substring(0, 10) === "Welcome to") {
      this.setState({"welcome_message": `Welcome to ${value}`});
    }
  }

  handleCheckbox(event) {
    let name = event.target.name;
    let value = event.target.checked;

    this.setState({[name]: value});
  }

  handleMultiCheckbox(event) {
    let name = parseInt(event.target.name);
    let value = event.target.checked;
    const key = event.target.dataset.key;

    var state = this.state[key]

    if (value === true) {
      if (!state.includes(name)) {
        state.push(name)
      }
    }
    else if (value === false) {
      if (state.includes(name)) {
        let index = state.indexOf(name)
        state.splice(index, 1)
      }
    }

    this.setState({key: state});
  }

  handleFileUpload(event) {
    let name = event.target.name;
    let value = event.target.files[0];

    this.setState({[name]: value});
  }

  clearImageUpload(event) {
    this.setState({'background_image': null});
  }

  handlePartialChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});

    this.debouncedHandlePersonSearch(value)
  }

  handlePersonSearch(partial) {
    if (partial === "") {
      return this.setState({ searchedUsers: null })
    }

    request('GET', `/v1/a/users/names_and_ids?user[search]=${partial}`, null, () => {this.setState({ unauthorized: true })})
      .then(data => {
        this.setState({
          searchedUsers: data,
          usersLoaded: true
        })
      })
      .catch(error => {
        reportErrorToHoneybadger(error)

        this.setState({ error })
      })
  }

  handleSubmit(event) {
    const formData = new FormData()
    formData.append('location[name]', this.state.name)
    formData.append('location[timezone]', this.state.timezone)
    formData.append('location[regulator_reference]', this.state.regulator_reference)
    formData.append('location[postcode]', this.state.postcode)
    formData.append('location[address]', this.state.address)
    formData.append('location[phone]', this.state.phone)
    formData.append('location[hidden]', this.state.hidden)
    formData.append('location[welcome_message]', this.state.welcome_message)
    formData.append('location[device_help_message]', this.state.device_help_message)
    formData.append('location[text_on_select]', this.state.text_on_select)
    formData.append('location[arrival_thank_you_message]', this.state.arrival_thank_you_message)
    formData.append('location[departure_thank_you_message]', this.state.departure_thank_you_message)
    formData.append('location[fire_logs_email]', this.state.fire_logs_email)
    formData.append('location[fire_log_access_pin]', this.state.fire_log_access_pin)
    formData.append('location[email_fire_log]', this.state.email_fire_log)

    this.state.user_ids.map((user_id, index) => (
      formData.append(`location[user_ids][]`, user_id)
    ))

    this.state.fire_log_user_ids.map((fire_log_user_id, index) => (
      formData.append(`location[fire_log_user_ids][]`, fire_log_user_id)
    ))

    if (this.state.upload_file !== null) {
      formData.append('location[background_image]', this.state.upload_file)
    }

    this.setState({updating: true})
    request('POST', `/v1/a/locations`, formData, () => {this.setState({ unauthorized: true })})
      .then(data => {
        this.setState({ id: data.id, updating: false, updated: true, errors: "" })
      })
      .catch(error => {
        reportErrorToHoneybadger(error)

        if (error instanceof Error) {
          this.setState({
            updating: false,
            error: error
          })
        }
        else {
          this.setState({
            updating: false,
            errors: error
          })
        }
      })

    event.preventDefault();
  }


  renderErrors(field) {
    if (this.state.errors[field]) {

      let capitalCaseField = field.charAt(0).toUpperCase() + field.slice(1)

      return (
        <div className="error">{`${capitalCaseField} ${this.state.errors[field]}`}</div>
      )
    }
  }

  renderUpdated(updated) {
    if (updated) {
      return (
        <div>
          Updated!
        </div>
      );
    }
  }

  loadImageBox(background_image) {
    if (background_image === null) {
      return (
        <input className="column" type="file" name="upload_file" onChange={this.handleFileUpload} />
      )
    }
    else {
      return (
        <div className="inline-image">
          <img src={`${process.env.REACT_APP_ROOT_DOMAIN}/${background_image}`} alt={`${this.state.name}`}></img>
          <button onClick={this.clearImageUpload}>Clear image</button>
        </div>
      )
    }
  }

  renderSaveButton() {
    if (adminUser()) {
      return <input type="submit" value="Save" disabled={this.state.updating} />
    }
    else {
      return <input type="submit" value="Sorry, you don't have permission to save changes" disabled="disabled" />
    }
  }

  renderFireLogUsersSelection(assigned_users, fire_log_user_ids) {
    if (assigned_users.length > 0) {
      return (
        <div className="scrollable-table">
          <table className="checkbox-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Send Emergency Fire Log Emails?</th>
              </tr>
            </thead>
            <tbody>
              {assigned_users.map((user) => (
                <tr key={user.id}>
                  <td>
                    <Link to={`/settings/users/${user.id}`}>{user.first_name} {user.last_name}</Link>
                  </td>
                  <td>
                    <Link to={`/settings/users/${user.id}`}>{user.email}</Link>
                  </td>
                  <td className="center-cell">
                    <input className="column" type="checkbox" data-name={user.email} name={user.id} data-key="fire_log_user_ids" checked={fire_log_user_ids.includes(user.id)} onChange={this.handleMultiCheckbox} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )
    }
    else {
      return (
        <NoticeBox type={"info"} text={"Only assigned Users can be set to be informed of emergency fire log use!"} />
      )
    }
  }

  render() {
    const { name, timezone, regulator_reference, postcode, address, phone, hidden, timezones, timezonesLoaded, organisationTimezoneLoaded, background_image, welcome_message, device_help_message, text_on_select, arrival_thank_you_message, departure_thank_you_message, fire_logs_email, fire_log_access_pin, email_fire_log, user_ids, fire_log_user_ids, users, partial, searchedUsers, usersLoaded, newLocationText, newLocationTextLoaded, id, updated, unauthorized, errors, error } = this.state;

    if (localStorage.license === "entry") {
      return <Redirect to="/upgrade" />
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (!supportUser()) {
      if (newLocationTextLoaded === false) {
        return <p>Loading ...</p>;
      }

      return (
        <div dangerouslySetInnerHTML={{__html: newLocationText}}></div>
      )
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (updated && id) {
      return <Redirect to={`/location/${id}`}/>
    }

    if (organisationTimezoneLoaded === false || timezonesLoaded === false || usersLoaded === false) {
      return <p>Loading ...</p>;
    }

    if (organisationTimezoneLoaded && timezonesLoaded && usersLoaded) {
      return (
        <div>
          <SetTitle title={"New Location | Locations"} />

          <form className="settings big-settings segment-settings" onSubmit={this.handleSubmit}>
            <div className="segment">
              <div className="row">
                <HelpText page={'location'} section={'name'} />

                <label className="column">Location Name:</label>{ this.renderErrors('name') }
                <input className="column" type="text" name="name" value={name} onChange={this.handleChange} />
              </div>

              <div className="row">
                <HelpText page={'location'} section={'timezone'} />

                <label className="column">Timezone:</label>{ this.renderErrors('timezone') }
                <select name="timezone" onChange={this.handleChange} value={timezone}>
                  <option value="">Select</option>
                  {timezones.map((timezone) => (
                    <option value={timezone} key={timezone}>{timezone}</option>
                  ))}
                </select>
              </div>

              <div className="row">
                <HelpText page={'location'} section={'regulatory'} />

                <label className="column">Regulatory Reference:</label>{ this.renderErrors('regulator_reference') }
                <input className="column" type="text" name="regulator_reference" value={regulator_reference} onChange={this.handleChange} />
              </div>

              <div className="row">
                <HelpText page={'location'} section={'postcode'} />

                <label className="column">Postcode:</label>{ this.renderErrors('postcode') }
                <input className="column" type="text" name="postcode" value={postcode} onChange={this.handleChange} />
              </div>

              <div className="row">
                <HelpText page={'location'} section={'address'} />

                <label className="column">Address:</label>{ this.renderErrors('address') }
                <textarea className="column" type="text" name="address" value={address} onChange={this.handleChange} />
              </div>

              <div className="row">
                <HelpText page={'location'} section={'phone'} />

                <label className="column">Phone Number:</label>{ this.renderErrors('phone') }
                <input className="column" type="text" name="phone" value={phone} onChange={this.handleChange} />
              </div>

              <div className="row">
                <HelpText page={'location'} section={'users'} />

                <label className="column">Users:</label>{ this.renderErrors('user') }
                <input name="partial" placeholder="Enter text to search" className="search-text" onChange={this.handlePartialChange} value={partial} />

                <div className="scrollable-table">
                  <table className="checkbox-table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Allow Access?</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(searchedUsers || users).map((user) => (
                        <tr key={user.id}>
                          <td>
                            <Link to={`/settings/users/${user.id}`}>{user.first_name} {user.last_name}</Link>
                          </td>
                          <td>
                            <Link to={`/settings/users/${user.id}`}>{user.email}</Link>
                          </td>
                          <td className="center-cell">
                            <input className="column" type="checkbox" data-name={user.email} name={user.id} data-key="user_ids" checked={user_ids.includes(user.id)} onChange={this.handleMultiCheckbox} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row">
                <HelpText page={'location'} section={'hidden'} />

                <input className="column" type="checkbox" name="hidden" id="hidden_checkbox" checked={hidden} onChange={this.handleCheckbox} />
                <label className="column checkbox-label" htmlFor="hidden_checkbox">Hidden?</label>{ this.renderErrors('hidden') }
              </div>
            </div>

            <div className="segment">
              <div className="row">
                <HelpText page={'location'} section={'image'} />

                <label className="column checkbox-label">Background Image:</label>{ this.renderErrors('background_image') }
                { this.loadImageBox(background_image) }
              </div>
            </div>

            <div className="segment">
              <div className="row">
                <HelpText page={'location'} section={'welcome'} />

                <label className="column">Welcome Message:</label>{ this.renderErrors('welcome_message') }
                <input className="column" type="text" name="welcome_message" value={welcome_message} onChange={this.handleChange} />
              </div>

              <div className="row">
                <HelpText page={'location'} section={'help'} />

                <label className="column">Device Help Message:</label>{ this.renderErrors('device_help_message') }
                <textarea aria-required="true" name="device_help_message" value={device_help_message} onChange={this.handleChange} />
              </div>

              <div className="row">
                <HelpText page={'location'} section={'text_on_select'} />

                <label className="column">Text on Select:</label>{ this.renderErrors('text_on_select') }
                <textarea aria-required="true" name="text_on_select" value={text_on_select} onChange={this.handleChange} />
              </div>

              <div className="row">
                <HelpText page={'location'} section={'arrival'} />

                <label className="column">Thank You Message on Arrival:</label>{ this.renderErrors('arrival_thank_you_message') }
                <input className="column" type="text" name="arrival_thank_you_message" value={arrival_thank_you_message} onChange={this.handleChange} />
              </div>

              <div className="row">
                <HelpText page={'location'} section={'leaving'} />

                <label className="column">Thank You Message on Leaving:</label>{ this.renderErrors('departure_thank_you_message') }
                <input className="column" type="text" name="departure_thank_you_message" value={departure_thank_you_message} onChange={this.handleChange} />
              </div>
            </div>

            <div className="segment">
              <div className="row">
                <HelpText page={'location'} section={'fire_logs_email'} />

                <label className="column">Fire Log Results Email:</label>{ this.renderErrors('fire_logs_email') }
                <input className="column" type="text" name="fire_logs_email" value={fire_logs_email} onChange={this.handleChange} />
              </div>

              <div className="row">
                <HelpText page={'location'} section={'fire_log_access_pin'} />

                <label className="column">Fire Log Access Pin for Devices:</label>{ this.renderErrors('fire_log_access_pin') }
                <input className="column" type="text" name="fire_log_access_pin" value={fire_log_access_pin} placeholder="Enter a 4-digit PIN" onChange={this.handleChange} />
              </div>

              <div className="row">
                <HelpText page={'location'} section={'fire_log_users'} />

                <label className="column">Users informed of emergency fire log use:</label>{ this.renderErrors('fire_log_user') }
                <input name="partial" placeholder="Enter text to search" className="search-text" onChange={this.handlePartialChange} value={partial} />

                {this.renderFireLogUsersSelection((searchedUsers || users).filter(user => user_ids.includes(user.id)), fire_log_user_ids)}
              </div>

              <div className="row">
                <HelpText page={'location'} section={'email_fire_log'} />

                <input className="column" type="checkbox" name="email_fire_log" id="email_fire_log_checkbox" checked={email_fire_log} onChange={this.handleCheckbox} />
                <label className="column checkbox-label" htmlFor="email_fire_log">Include fire log in emergency link emails?</label>{ this.renderErrors('email_fire_log') }
              </div>
            </div>

            <div className="segment">
              { this.renderSaveButton() }
              { this.renderUpdated(updated) }
              { renderErrorWarning(errors) }
            </div>
          </form>
        </div>
      );
    }
  }

  componentDidMount() {
    if (supportUser()) {
      request('GET', `/v1/a/organisations/0`, null, () => {this.setState({ unauthorized: true })})
        .then(data => {
          localStorage.setItem('organisationName', data.name);
          this.setState({
            timezone: data.timezone,
            organisationTimezoneLoaded: true
          })
        })
        .catch(error => {
          reportErrorToHoneybadger(error)

          this.setState({ error, organisationTimezoneLoaded: true })
        })

      request('GET', `/list_of_timezones`, null, () => {this.setState({ unauthorized: true })})
        .then(data => {
          this.setState({ timezones: data.timezones, timezonesLoaded: true })
        })
        .catch(error => {
          reportErrorToHoneybadger(error)

          this.setState({ error, timezonesLoaded: true })
        })

      request('GET', `/v1/a/users/names_and_ids`, null, () => {this.setState({ unauthorized: true })})
        .then(data => {
          const current_user = data.find(user => user.current_user === true)

          if (current_user) {
            const current_user_id = current_user.id

            this.setState({
              users: data,
              user_ids: [current_user_id],
              usersLoaded: true
            })
          }
          else {
            // No current_user means Support is creating the Location
            this.setState({
              users: data,
              usersLoaded: true
            })
          }
        })
        .catch(error => {
          reportErrorToHoneybadger(error)

          this.setState({ error, usersLoaded: true })
        })
    }
    else {
      request('GET', `/v1/a/locations/new_location_text`, null, () => {this.setState({ unauthorized: true })})
        .then(data => {
          this.setState({
            newLocationText: data.new_location_text,
            newLocationTextLoaded: true
          })
        })
        .catch(error => {
          reportErrorToHoneybadger(error)

          this.setState({ error, newLocationTextLoaded: true })
        })
    }
  }
}

export default CreateLocation;
