import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { request, reportErrorToHoneybadger } from '../../helpers/Requests'

import FeedbackAnswer from '../../components/analysis/charts/FeedbackAnswer';

class FeedbackAnswers extends Component {
  state = {
    answers: [],
    loaded: false,
    error: null,
    unauthorized: null,
    invalid_params: false
  };

  render() {
    const { answers, loaded, error, unauthorized, invalid_params } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (this.props.feedback === "") {
      return <p>Please select a Feedback Type</p>;
    }

    if (invalid_params === true) {
      return <p>Waiting for valid date range ...</p>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      if (answers.length === 0) {
        return <p>No answers found for this Feedback Type and date range.</p>;
      }
      else {
        return (
          <div className="widgets">
            {answers.map((answer, index) => (
              <FeedbackAnswer answer={answer} key={index} index={index} />
            ))}
          </div>
        );
      }
    }
  }

  componentDidMount() {
    if (this.props.date_from !== "" && this.props.date_to !== "") {
      var headers = new Headers();
      headers.append("Content-Type", "application/x-www-form-urlencoded");

      if (this.props.feedback !== "") {
        let params = `analysis[date_from]=${this.props.date_from}&analysis[date_to]=${this.props.date_to}&analysis[feedback]=${this.props.feedback}&analysis[location]=${this.props.location_id}`

        request('GET', `/v1/a/analysis/feedback_answers?${params}`, null, () => {this.setState({ unauthorized: true })})
          .then(data => {
            this.setState({ answers: data, loaded: true })
          })
          .catch(error => {
            reportErrorToHoneybadger(error)

            this.setState({ error, loaded: true })
          })
      }
    }
    else {
      this.setState({
        invalid_params: true
      })
    }
  }
}

export default FeedbackAnswers;
