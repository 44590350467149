
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { request, reportErrorToHoneybadger } from '../../helpers/Requests'
import LoggedOutHeader from '../../components/header/LoggedOutHeader';
import BigLogo from '../../components/header/BigLogo';
import SetTitle from '../../components/shared/SetTitle';

class PasswordReset extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    password: "",
    token: "",
    updated: false,
    errors: "",
    error: ""
  };

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({[name]: value});
  }

  handleSubmit(event) {
    const json = JSON.stringify({
      "password_reset": {
        "password": this.state.password,
        "reset_token": this.props.match.params.token,
      }
    })

    request('POST', `/v1/a/users/reset_password`, json)
      .then(data => {
        this.setState({ updated: true, errors: "" })
      })
      .catch(error => {
        reportErrorToHoneybadger(error)

        if (error.errors) {
          this.setState({
            errors: error
          })
        }
        else {
          this.setState({
            error: error
          })
        }
      })

    event.preventDefault();
  }

  renderErrors() {
    if (this.state.errors) {
      return (
        <div className="errors">
          Sorry, looks like you have some errors:
          <ul>
            {this.state.errors.errors.map((error) => (
              <li>{error}</li>
            ))}
          </ul>
        </div>
      )
    }
  }

  renderUpdated() {
    if (this.state.updated) {
      return (
        <div className="login-form forgotten-password">
          <p className="success">Thank you, your password has been set. You may now <Link to={'/login'}>log in</Link>.</p>
        </div>
      )
    }
  }

  renderForm() {
    if (this.state.updated === false) {
      return (
        <div className="login-form forgotten-password">
          <p>Please choose a password and enter it below to complete your account setup.</p>
          <p>Make sure you type it correctly, as we will only ask you to type it once!</p>
          <form onSubmit={this.handleSubmit}>
            <label>
              <input placeholder=" new password" aria-required="true" type="password" name="password" value={this.state.password} onChange={this.handleChange} />
            </label>
            <br />
            <input type="submit" value="Submit" />
          </form>

          { this.renderErrors() }
        </div>
      )
    }
  }

  render() {
    const { error } = this.state;

    if (error) {
      return <div>{error.message}</div>;
    }

    return (
      <div className="login-page">
        <SetTitle title={"Set Password"} />
        <LoggedOutHeader />

        <div className="center-container">
          <div className="login-box">
            <p className="welcome-to">Welcome to...</p>

            <BigLogo />

            {this.renderForm()}
            {this.renderUpdated()}
          </div>

        </div>

      </div>
    );
  }
}

export default PasswordReset;
