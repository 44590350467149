import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

import { request, reportErrorToHoneybadger } from '../../helpers/Requests'
import SetTitle from '../../components/shared/SetTitle';
import HelpText from '../../components/help/HelpText';
import Audits from '../../components/shared/Audits';

import { adminUser, renderErrorWarning } from '../../utilities/Forms.js'

class UpdateCustomAttribute extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleProfileMultiCheckbox = this.handleProfileMultiCheckbox.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    name: "",
    include_in_fire_log: false,
    answer_type: "text",
    required: false,
    profile_ids: [],

    profiles: [],

    loaded: false,
    profilesLoaded: false,
    unauthorized: "",
    errors: "",
    error: ""
  };

  handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({[name]: value});
  }

  handleCheckbox(event) {
    const name = event.target.name;
    const value = event.target.checked;

    this.setState({[name]: value});
  }

  handleProfileMultiCheckbox(event) {
    const name = parseInt(event.target.name);
    const value = event.target.checked;

    let profile_ids = this.state.profile_ids

    if (value === true) {
      if (!profile_ids.includes(name)) {
        profile_ids.push(name)
      }
    }
    else if (value === false) {
      if (profile_ids.includes(name)) {
        let index = profile_ids.indexOf(name)
        profile_ids.splice(index, 1)
      }
    }

    this.setState({profile_ids: profile_ids});
  }

  handleSubmit(event) {
    let id = this.props.id;

    const json = JSON.stringify({
      "custom_attribute": {
        "name": this.state.name,
        "answer_type": this.state.answer_type,
        "required": this.state.required,
        "include_in_fire_log": this.state.include_in_fire_log,
        "profile_ids": this.state.profile_ids
      }
    })

    request('PUT', `/v1/a/custom_attributes/${id}`, json, () => {this.setState({ unauthorized: true })})
      .then(data => {
        this.setState({ errors: "" })
        sessionStorage.setItem("updateSuccess", "Custom Attribute updated successfully!");
        this.props.closeEditPanel()
      })
      .catch(error => {
        reportErrorToHoneybadger(error)

        if (error instanceof Error) {
          this.setState({ updating: false, error: error })
        }
        else {
          this.setState({ updating: false, errors: error })
        }
      })

    event.preventDefault();
  }


  renderErrors(field) {
    if (this.state.errors[field]) {

      let capitalCaseField = field.charAt(0).toUpperCase() + field.slice(1)

      return (
        <div className="error">{`${capitalCaseField} ${this.state.errors[field]}`}</div>
      )
    }
  }

  renderSaveButton() {
    if (adminUser()) {
      return <input type="submit" value="Save" />
    }
    else {
      return <input type="submit" value="Sorry, you don't have permission to save changes" disabled="disabled" />
    }
  }

  render() {

    const {
      name, answer_type, required, include_in_fire_log, profile_ids, profiles,

      loaded, profilesLoaded,

      unauthorized, errors, error
    } = this.state;

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false || profilesLoaded === false) {
      return <p>Loading ...</p>;
    }

    return (
      <div>
        <SetTitle title={`Edit Custom Attribute | ${name} | Custom Attributes`} />

        <form className="settings big-settings segment-settings" onSubmit={this.handleSubmit}>
          <div className="segment">
            <div className="row">
              <HelpText page={'custom_attributes'} section={'name'} />

              <label className="column">Custom Attribute Name:</label>{ this.renderErrors('name') }
              <input className="column" type="text" name="name" value={name} onChange={this.handleChange} />
            </div>
          </div>

          <div className="segment">
            <div className="row">
              <HelpText page={'custom_attributes'} section={'answer_type'} />

              <label className="column">Type</label>{ this.renderErrors('answer_type') }

              <div className="radial-three-options">
                <div className="radial-option">
                  <input className="column" type="radio" name="answer_type" id="text" value="text" checked={answer_type === "text"} onChange={this.handleChange} />
                  <label htmlFor="always">Text</label>
                </div>

                <div className="radial-option">
                  <input className="column" type="radio" name="answer_type" id="boolean" value="boolean" checked={answer_type === "boolean"} onChange={this.handleChange} />
                  <label htmlFor="boolean">Yes/No</label>
                </div>

                <div className="radial-option">
                  <input className="column" type="radio" name="answer_type" id="acknowledgement" value="acknowledgement" checked={answer_type === "acknowledgement"} onChange={this.handleChange} />
                  <label htmlFor="acknowledgement">Acknowledgement</label>
                </div>
              </div>
            </div>
          </div>

          <div className="segment">
            <div className="row">
              <HelpText page={'custom_attributes'} section={'required'} />

              <input className="column" type="checkbox" name="required" id="required_checkbox" checked={required} onChange={this.handleCheckbox} />
              <label className="column checkbox-label" htmlFor="required_checkbox">Required?</label>{ this.renderErrors('required') }
            </div>

            <div className="row">
              <HelpText page={'custom_attributes'} section={'include_in_fire_log'} />

              <input className="column" type="checkbox" name="include_in_fire_log" id="include_in_fire_log_checkbox" checked={include_in_fire_log} onChange={this.handleCheckbox} />
              <label className="column checkbox-label" htmlFor="include_in_fire_log_checkbox">Include in Fire Log?</label>{ this.renderErrors('include_in_fire_log') }
            </div>
          </div>

          <div className="segment">
            <div className="row">
              <HelpText page={'custom_attributes'} section={'profiles'} />

              <label className="column">Profiles:</label>{ this.renderErrors('profile') }
              <div className="scrollable-table">
                <table className="checkbox-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Use</th>
                    </tr>
                  </thead>
                  <tbody>
                    {profiles.map((profile) => (
                      <tr key={profile.id}>
                        <td>
                          <Link to={`/configuration/profiles/${profile.id}`}>{profile.name}</Link>
                        </td>
                        <td className="center-cell">
                          <input className="column" type="checkbox" data-name={profile.name} name={profile.id} checked={profile_ids.includes(profile.id)} onChange={this.handleProfileMultiCheckbox} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="segment">
            { this.renderSaveButton() }
            { renderErrorWarning(errors) }
          </div>
        </form>

        <Audits klass="CustomAttribute" id={this.props.id} />
      </div>
    );
  }

  componentDidMount() {
    const id = this.props.id;

    request('GET', `/v1/a/custom_attributes/${id}`, null, () => {this.setState({ unauthorized: true })})
      .then(data => {
        this.setState({
          name: data.name,
          answer_type: data.answer_type,
          required: data.required,
          include_in_fire_log: data.include_in_fire_log,

          profile_ids: data.profile_ids,

          loaded: true
        })
      })
      .catch(error => {
        reportErrorToHoneybadger(error)

        this.setState({ error, loaded: true })
      })

    request('GET', `/v1/a/profiles/names_and_ids`, null, () => {this.setState({ unauthorized: true })})
      .then(data => {
        this.setState({ profiles: data, profilesLoaded: true })
      })
      .catch(error => {
        reportErrorToHoneybadger(error)

        this.setState({ error, profilesLoaded: true })
      })
  }
}

export default UpdateCustomAttribute;
